.PathTreePanel-TreeView {
  padding: 0;
  list-style: none;
  outline: 0;
  flex-grow: 1;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 8px 0 0;
  min-height: calc(100% - 8px);

  .PathTreePanel-TreeItem {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: 0;
    user-select: none;
    position: relative;

    .PathTreePanel-TreeItemContent {
      padding: 0 0 0 8px;
      width: auto;
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      position: relative;

      .PathTreePanel-TreeItemIconContainer {
        margin-right: 4px;
        width: 15px;
        display: flex;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        justify-content: center;

        svg {
          font-size: 18px;
        }
      }

      .PathTreePanel-TreeItemLabel {
        width: 100%;
        min-width: 0;
        padding-left: 4px;
        position: relative;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        height: 1.5em;
        letter-spacing: 0.00938em;
        display: flex;
        align-items: center;

        .PathTreePanel-TreeItemName {
          flex-grow: 1;
          overflow: hidden;
          height: calc(1.5em - 2px);
          margin-right: 1px;
        }

        .PathTreePanel-TreeItemName_edit {
          outline: 1px #7f47b3 solid;
        }

        .PathTreePanel-TreeItemName_preview {
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .PathTreePanel-TreeFuncIcon {
          height: calc(1em * 0.7) !important;
          vertical-align: middle;
          visibility: hidden;
        }

        .PathTreePanel-TreeFuncIcon_show {
          visibility: visible;
        }
      }

      .PathTreePanel-TreeItemLabel:hover > .PathTreePanel-TreeFuncIcon {
        visibility: visible;
      }
    }

    .PathTreePanel-TreeItemContent:hover {
      background-color: var(--hover-color);
    }

    .PathTreePanel-TreeItemContent.focused {
      background-color: var(--focused-color);
    }

    .PathTreePanel-TreeItemContent.selected {
      background-color: rgba(127, 71, 179, 0.16);
    }

    .PathTreePanel-TreeItemContent.selected:hover {
      background-color: rgba(127, 71, 179, 0.24);
    }

    .PathTreePanel-TreeItemContent.selected.focused {
      background-color: rgba(127, 71, 179, 0.28);
    }

    .PathTreePanel-TreeItemContent.deny-drop {
      opacity: 0.5;
    }

    .PathTreePanel-TreeItemChildrenGroup {
      height: auto;
      overflow: visible;
      margin: 0;
      padding: 0;
      // margin-left: 17px;
      min-height: 0px;
      transition-duration: 300ms;
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .PathTreePanel-DraggingDividerTop::after {
    content: "";
    border-top: 1px solid #7f47b3;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  .PathTreePanel-DraggingDividerBottom::after {
    content: "";
    border-top: 1px solid #7f47b3;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
