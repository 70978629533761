#CoordinateSystemModal {
  padding: 16px;
  width: 768px;
  max-width: 80%;
  min-height: 96px;
  outline: none !important;

  #CoordinateSystem-Body {
    display: flex;
    width: 100%;
    gap: 16px;

    #CoordinateSystems-LeftSide {
      flex-grow: 1;
      max-width: calc(60% - 16px);

      #CoordinateSystemsList {
        flex-grow: 1;
        min-height: calc(256px - 8px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 8px; // maybe 16px
        position: relative;

        #CoordinateSystemsList-Content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .CoordinateSystemsList-Item {
            .CoordinateSystemsList-ItemApplyButton {
              visibility: hidden;
            }
          }

          .CoordinateSystemsList-Item:hover {
            .CoordinateSystemsList-ItemApplyButton {
              visibility: inherit;
            }
          }
        }
      }
    }

    #CoordinateSystems-PreviewSection {
      width: 40%;
      min-width: 256px;

      #CoordinateSystems-ImagePreview {
        width: 100%;
        line-height: 0;
        border: 1px solid var(--text-primary-color);
        position: relative;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
}
