#PreferencesModal {
  padding: 16px;
  width: 512px;
  max-width: 80%;
  min-height: 96px;
  outline: none !important;

  hr {
    margin: 16px 0;
  }
}
