@import "app/common.blocks/index";

#root {
  overflow: hidden; // UX: Fix potential issue on device emulator

  > #Root-Container {
    display: flex;
    width: 100vw;
    width: 100svw;
    height: 100vh;
    height: 100svh;
    box-sizing: border-box;

    &[data-layout="classic"] {
      @import "app/classic.blocks/index";
    }

    &[data-layout="exclusive"] {
      @import "app/exclusive.blocks/index";
    }

    &[data-layout="mobile"] {
      @import "app/mobile.blocks/index";
    }

    &[data-theme="light"] {
      --bg-body-color: #ffffff;
      --bg-default-color: #ffffff;
      --bg-paper-color: #f3f3f3;
      --text-primary-color: rgba(0, 0, 0, 0.87);
      --text-disabled-color: rgba(0, 0, 0, 0.6);
      --link-color: rgba(0, 0, 0, 0.87);
      --hover-color: rgba(0, 0, 0, 0.08);
      --focused-color: rgba(0, 0, 0, 0.12);
      --primary-main-color: #5c469c;
      background-color: var(--bg-body-color);

      code {
        background-color: #e3e3e3;
      }

      td,
      th {
        border: 1px solid #e3e3e3;
      }

      th {
        background-color: #f6f6f6;
      }
    }

    &[data-theme="dark"] {
      --bg-body-color: #292929;
      --bg-default-color: #1e1e1e;
      --bg-paper-color: #353535;
      --text-primary-color: rgba(255, 255, 255, 1);
      --text-disabled-color: rgba(255, 255, 255, 0.5);
      --link-color: rgba(255, 255, 255, 0.87);
      --hover-color: rgba(255, 255, 255, 0.04);
      --focused-color: rgba(255, 255, 255, 0.12);
      --primary-main-color: #7f47b3;
      background-color: var(--bg-body-color);

      code {
        background-color: var(--bg-default-color);
      }

      td,
      th {
        border: 1px solid #444444;
      }

      th {
        background-color: #3c3c3c;
      }
    }
  }
}
