*::-webkit-scrollbar {
  width: 6.4px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3.2px;
}

body {
  margin: 0;
  overflow: hidden;
  touch-action: none;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0 !important;
}

a {
  color: var(--link-color) !important;
  text-decoration: underline !important;
}

code {
  display: inline-block;
  padding: 0px 6.4px;
  border-radius: 3.2px;
  font-family: "Roboto Mono", monospace !important;
  font-size: 14.4px !important;
}

blockquote {
  background-color: var(--bg-paper-color);
  border-left: 3.2px solid var(--bg-default-color);
  padding: 3.2px 6.4px;
  margin: 3.2px 0;
  font-style: italic;
}

img.markdown-style {
  max-width: 100%;
}

ol.markdown-style,
ul.markdown-style {
  -webkit-padding-start: calc(1em + 1ex);
  padding-inline-start: calc(1em + 1ex);

  li {
    word-wrap: break-all;
    margin-block: calc(0.25em + 0.25ex);
  }

  li:has(> input:first-child) {
    // Hide dot in checkbox
    list-style-type: none;
    position: relative;

    input {
      position: absolute;
      left: -1.75em;
      top: 0.25em;
    }
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding-block: 0.6ex;
  padding-inline: 1ex;
}

// For menu list
.MuiPaper-root {
  transition: none !important;
}

// For accordion
.MuiPaper-root {
  background-image: none !important; // remove tiny dot pattern in accordion
}

.notistack-SnackbarContainer {
  // cspell:disable-line
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

// See: https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices
@mixin on-hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}
