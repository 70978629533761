#ConfirmationModal {
  padding: 16px;
  min-width: 320px;
  max-width: 80%;
  min-height: 96px;
  outline: none !important;
}

.ConfirmationModal-DescriptionBox {
  display: flex;

  > * {
    flex-grow: 1;
    width: 0;
  }
}

.ConfirmationModal-InputBox {
  width: 100%;

  > * {
    width: 100%;
  }
}

.ConfirmationModal-ButtonBox {
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;

  *:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
