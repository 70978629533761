.CanvasTooltip {
  user-select: none;
  pointer-events: none !important;
}

.CanvasTooltip-Label {
  padding: 8px 12px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
}
