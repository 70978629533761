.FormButton-Button {
  all: unset;
  margin: 0;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(255, 255, 255, 0.23);
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  align-items: center;
  padding: 8.5px 14px;
  // box-sizing: content-box;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 8px;

  &:hover {
    border-color: var(--text-primary-color);
  }

  &:focus {
    border-color: var(--primary-main-color);
    border-width: 2px;
    padding: 7.5px 13px;
  }

  > svg {
    font-size: 1em;
    width: 1em;
    height: 1em;
  }
}
