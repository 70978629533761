#LeftSection {
  position: fixed;
  top: 8px;
  left: calc(8px + 48px + 8px);
  width: calc(288px);
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100% - 16px);

  .FloatingPanel {
    width: 288px;
  }
}

#RightSection {
  position: fixed;
  top: 8px;
  right: calc(8px - 8px + 48px + 8px);
  width: calc(352px + 8px);
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100% - 16px);

  .FloatingPanel {
    margin-bottom: 8px;
    width: 352px;
  }

  .FloatingPanel:last-child {
    margin-bottom: 0;
  }
}

#FieldCanvas-Container {
  width: 100%;
  height: 100%;
  margin: 0;
}

.FloatingPanel {
  background-color: var(--bg-paper-color);
  padding: 16px;
  border-radius: 4px;
  color: var(--text-primary-color);
  box-sizing: border-box;
  border: 0.5px solid var(--bg-default-color);

  .FloatingPanel-Header {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.PanelIcon-Box {
  position: fixed;
  margin: 0;
}

.PanelIcon {
  width: 48px;
  height: 48px;
  background-color: var(--bg-paper-color);
  color: var(--text-primary-color);
  padding: 0;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 8px;
  position: relative;

  &.disabled {
    cursor: auto;
    color: var(--text-disabled-color);

    @include on-hover {
      background-color: var(--bg-paper-color) !important;
    }
  }

  > svg {
    font-size: 36px;
    // UX: Using vertical-align: middle with line-height can not center the icon with 1 px offset
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @include on-hover {
    background-color: var(--bg-default-color);
  }
}

#MousePositionPresentation {
  position: fixed;
  color: var(--text-primary-color);
  user-select: none;
}

@import "./panel/PathTreePanel.scss";
@import "./speed-canvas/SpeedCanvasElement";
